import styled from "@emotion/styled";
import { t } from "@lingui/macro";
import React, { useContext } from "react";
import { AboutPage, CareersPage, ContactPage, HomePage, TeamPage, TermsPage, TermsSection } from "../../pages";
import { default as colors } from "../../utils/colors.json";
import Button from "../Button/Button";
import { Link, useNavigate } from "../Link/Link";
import { pageContext } from "../PageProvider/PageProvider";
import { BaseLayout } from "../PageSection/PageSection";
import logoLg from "./diet-doctor-logo-footer.svg";
import facebookSvg from "./facebook.svg";
import instagramSvg from "./instagram.svg";
import pinterestSvg from "./pinterest.svg";
import twitterSvg from "./twitter.svg";
import youtubeSvg from "./youtube.svg";

const Container = styled(BaseLayout)`
  background-color: #0e3478;
  color: #fff;

  @media print {
    display: none;
  }
`;

const InnerFooter = styled.div`
  padding-top: 30px;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
`;

const LogoAndNav = styled.div`
  display: flex;
`;

const LogoBox = styled.div`
  a {
    text-decoration: none;
  }
`;

const LogoImageLg = styled.img`
  height: 115px;
  padding-right: 30px;

  @media (min-width: 1024px) {
    height: 133px;
  }
`;

const FooterNav = styled.ul`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: -0.15px;
  margin: 0;
  padding: 0;
  list-style: none;
  color: ${colors.WHITE};

  @media (min-width: 1024px) {
    font-size: 26px;
    line-height: 40px;
  }
`;

const NavLink = styled.span`
  & > a {
    text-decoration: none;
    color: ${colors.WHITE};
  }
`;

const ExtraInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 0;
  }
`;

const SocialFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0 40px;

  @media (min-width: 1024px) {
    padding: 85px 0 30px;
  }
`;

const SocialImage = styled.img`
  height: 50px;
  width: 50px;
  margin: 0 2px;

  @media (min-width: 1024px) {
    margin: 0 10px;
  }
`;

const BottomFooter = styled.div`
  display: block;
  font-size: 15px;
  line-height: 22px;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    font-size: 20px;
    line-height: 30px;
  }
`;

const BottomFooterLinks = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;

  @media (min-width: 1024px) {
    justify-content: normal;
  }
`;
const BottomFooterLinkItem = styled.li`
  margin: 0 3px;

  @media (min-width: 1024px) {
    margin: 0 10px;
  }

  & > a {
    color: ${colors.WHITE};
  }
`;

interface FooterProps {
  className?: string;
}

const Footer: React.FunctionComponent<FooterProps> = ({ className }) => {
  const navigate = useNavigate();
  const tt = useTranslations();
  const { locale } = useContext(pageContext);

  return (
    <Container className={className}>
      <InnerFooter>
        <LogoAndNav>
          <LogoBox>
            <Link to={HomePage}>
              <LogoImageLg src={logoLg} height="115px" alt="Diet Doctor Logo" />
            </Link>
          </LogoBox>
          <FooterNav>
            <li>
              <NavLink>
                <Link to={AboutPage}>{tt.linkAboutUs}</Link>
              </NavLink>
            </li>
            <li>
              <NavLink>
                <a
                  href={ContactPage.path(locale, undefined, undefined)}
                  title={tt.linkContact}
                  rel="noopener"
                  target="_parent"
                >
                  {tt.linkContact}
                </a>
              </NavLink>
            </li>
            <li>
              <NavLink>
                <Link to={CareersPage}>{tt.linkCareers}</Link>
              </NavLink>
            </li>
            <li>
              <NavLink>
                <Link to={TeamPage}>{tt.linkTeam}</Link>
              </NavLink>
            </li>
          </FooterNav>
        </LogoAndNav>
        <ExtraInfoContainer>
          <div>
            <Button color="darkpink" onClick={() => navigate({ to: CareersPage })}>
              {tt.buttonWeAreHiring}
            </Button>
          </div>
        </ExtraInfoContainer>
      </InnerFooter>
      <SocialFooter>
        <a href={tt.facebookLink} title="Facebook" rel="noopener noreferrer" target="_blank">
          <SocialImage width="50" height="50" src={facebookSvg} alt="facebook" />
        </a>
        <a href={tt.instagramLink} title="Instagram" rel="noopener noreferrer" target="_blank">
          <SocialImage width="50" height="50" src={instagramSvg} alt="instagram" />
        </a>
        <a href={tt.twitterLink} title="Twitter" rel="noopener noreferrer" target="_blank">
          <SocialImage width="50" height="50" src={twitterSvg} alt="twitter" />
        </a>
        <a href={tt.pinterestLink} title="Pinterest" rel="noopener noreferrer" target="_blank">
          <SocialImage width="50" height="50" src={pinterestSvg} alt="pinterest" />
        </a>
        <a href={tt.youtubeLink} title="YouTube" rel="noopener noreferrer" target="_blank">
          <SocialImage width="50" height="50" src={youtubeSvg} alt="youtube" />
        </a>
      </SocialFooter>
      <BottomFooter>
        <BottomFooterLinks>
          <BottomFooterLinkItem>
            <Link to={TermsPage} query={{ section: TermsSection.TERMS }}>
              {tt.linkTerms}
            </Link>
          </BottomFooterLinkItem>
          <BottomFooterLinkItem>
            <Link to={TermsPage} query={{ section: TermsSection.COPYRIGHT }}>
              {tt.linkCopyright}
            </Link>
          </BottomFooterLinkItem>
          <BottomFooterLinkItem>
            <Link to={TermsPage} query={{ section: TermsSection.PRIVACY_POLICY }}>
              {tt.linkPrivacy}
            </Link>
          </BottomFooterLinkItem>
          <BottomFooterLinkItem>
            <Link to={TermsPage} query={{ section: TermsSection.DISCLAIMER }}>
              {tt.linkDisclaimer}
            </Link>
          </BottomFooterLinkItem>
        </BottomFooterLinks>
        <div className="block text-center text-white opacity-70">Diet Doctor © {new Date().getFullYear()}</div>
      </BottomFooter>
    </Container>
  );
};

export default Footer;

function useTranslations() {
  return {
    linkAboutUs: t({
      id: "Footer.link.about-us",
      message: "About us",
      comment: 'Label for link to "about us" page found at the page footer',
    }),
    linkContact: t({
      id: "Footer.link.contact",
      message: "Contact",
      comment: 'Label for link to "contact" page found at the page footer',
    }),
    linkCareers: t({
      id: "Footer.link.careers",
      message: "Careers",
      comment: 'Label for link to "careers" page found at the page footer',
    }),
    linkTeam: t({
      id: "Footer.link.dd-team",
      message: "Team",
      comment: 'Label for link to "team" page found at the page footer',
    }),
    linkCopyright: t({
      id: "Footer.link.copyright",
      message: "Copyright",
      comment: 'Label for link to "copyright" page found at the page footer',
    }),
    linkPrivacy: t({
      id: "Footer.link.privacy",
      message: "Privacy",
      comment: 'Label for link to "copyright" page found at the page footer',
    }),
    linkDisclaimer: t({
      id: "Footer.link.disclaimer",
      message: "Disclaimer",
      comment: 'Label for link to "disclaimer" page found at the page footer',
    }),
    linkTerms: t({
      id: "Footer.link.terms",
      message: "Terms and conditions",
      comment: 'Label for link to "terms" page found at the page footer',
    }),
    facebookLink: t({
      id: "Footer.link.facebook",
      message: "https://www.facebook.com/thedietdoctor",
      comment: "Link for Facebook page",
    }),
    instagramLink: t({
      id: "Footer.link.instagram",
      message: "https://www.instagram.com/diet_doctor",
      comment: "Link for Instagram page",
    }),
    twitterLink: t({
      id: "Footer.link.twitter",
      message: "https://twitter.com/DietDoctor",
      comment: "Link for Twitter page",
    }),
    pinterestLink: t({
      id: "Footer.link.pinterest",
      message: "https://www.pinterest.com/thedietdoctor",
      comment: "Link for Pinterest page",
    }),
    youtubeLink: t({
      id: "Footer.link.youtube",
      message: "https://www.youtube.com/c/DietDoctorVideo",
      comment: "Link for YouTube page",
    }),
    buttonWeAreHiring: t({
      id: "Footer.button.we-are-hiring",
      message: `We're hiring!`,
      comment: 'Label for "We\'re hiring!" button found at the page footer',
    }),
  };
}
